.gallery-section {
    .container {
        width: 95%;
    }

    .content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    figure {
        position: relative;
        overflow: hidden;
        cursor: pointer;
        margin: 10px 0;

        img {
            height: 260px;
        }

        &:last-of-type {
            margin-right: 0;
        }

        &:hover::before {
            -webkit-animation: shine 0.75s;
            animation: shine 0.75s;
        }

        &::before {
            position: absolute;
            top: 0;
            left: -75%;
            z-index: 2;
            display: block;
            content: '';
            width: 50%;
            height: 100%;
            background: -webkit-linear-gradient(
                left,
                rgba(255, 255, 255, 0) 0%,
                rgba(255, 255, 255, 0.3) 100%
            );
            background: linear-gradient(
                to right,
                rgba(255, 255, 255, 0) 0%,
                rgba(255, 255, 255, 0.3) 100%
            );
            -webkit-transform: skewX(-25deg);
            transform: skewX(-25deg);
        }
    }
}

.share-icons {
    display: flex;

    .icon-share {
        display: flex;
        align-items: center;
        margin-right: 10px;

        &:last-of-type {
            margin-right: 0px;
        }

        .icon {
            margin-right: 5px;
        }
    }
}

@-webkit-keyframes shine {
    100% {
        left: 125%;
    }
}

@keyframes shine {
    100% {
        left: 125%;
    }
}

@media only screen and (max-width: 420px) {
    .gallery-section {
        .content {
            justify-content: center !important;
        }

        figure {
            margin: 10px;

            img {
                height: 200px;
            }
        }
    }
}
