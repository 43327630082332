.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 0 0px;
    .FillImage(top center);

    .footer-info {
        background-color: black;
        color: #959595;
        text-align: center;
        width: 100%;
        font-size: 12px;
        padding: 15px 0;
        line-height: 1.5;
    }

    .form {
        display: flex;
        flex-direction: column;

        .social-media {
            display: flex;
            align-self: center;
            margin-top: 30px;
            margin-bottom: 100px;

            .link {
                .Transition(all 0.35s);
                font-size: 33px;
                color: white;
                border-radius: 22px;
                text-align: center;

                &.facebook {
                    margin-right: 7px;
                    background-color: #3b5998;
                    width: 43px;
                    height: 37px;
                    padding-top: 7px;

                    &:hover {
                        background-color: #2d4373;
                        color: #c0ccd6;
                    }
                }

                &.instagram {
                    background-color: #3f729b;
                    padding-bottom: 3px;
                    width: 44px;
                    height: 36px;
                    padding: 6px 0 3px 1px;

                    &:hover {
                        background-color: #305777;
                        color: #c0ccd6;
                    }
                }
            }
        }
    }

    .newsletter-form {
        display: flex;
        flex-direction: column;
        align-items: center;

        button {
            border: none;
            outline: none;
            padding: 0;
            margin: 0;
            margin-bottom: 5px;
            align-self: center;
        }

        .email {
            .Transition(all 0.35s);
            font-size: 25px;
            padding: 13px 25px;
            background-color: #cb9f6b;
            color: #222222;
            cursor: pointer;

            &:hover {
                background-color: #000;
                color: #fff;
            }
        }

        input {
            width: 320px;
            height: 51px;
            background-color: #f9f9f9;
            border: none;
            border-radius: 0;
            box-shadow: none;
            margin-right: 5px;
            margin-bottom: 5px;
            padding: 0 20px 0 15px;
            color: #555555;
            font-weight: 400;
            outline: none;
        }
    }
}

@media (min-width: 991px) {
    .footer {
        .footer-info {
            font-size: 14px;
        }

        .newsletter-form {
            flex-direction: row;
        }
    }
}
