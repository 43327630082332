.Transition(@transition) {
    -webkit-transition: @transition;
    -moz-transition: @transition;
    transition: @transition;
}

.FillImage(@position) {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: @position;
}
