.reservation-section {
    .FillImage(right bottom);
    font-size: 12px;

    .title-section {
        margin-bottom: 30px !important;
    }

    .note {
        font-weight: bold;
        font-size: 13px;
        align-self: center;
        margin-bottom: 6px;

        &:last-of-type {
            margin-bottom: 40px;
        }
    }

    .reservation-form {
        margin-top: 30px;
        display: flex;
        flex-direction: column;

        .field-input,
        .date-picker {
            margin-right: 5px;
        }

        .one-line {
            margin-bottom: 5px;
        }

        .comment {
            display: flex;
            flex: 1;

            input {
                flex: 1;
            }
        }

        .field-input {
            &.wide input {
                width: 210px;
            }

            .error {
                font-size: 14px;
                top: 17px;
            }
        }
        input:not(.date-picker input) {
            border-radius: 10px;
            border: solid 1px rgba(0, 0, 0, 0.54);
            height: 25px;
            padding: 10px;
            background-color: transparent;
        }

        .date-picker {
            width: 180px;

            > div {
                border-radius: 10px;
                border: solid 1px rgba(0, 0, 0, 0.54);

                fieldset {
                    border: 0;
                }
            }

            input {
                font-size: 12px;
                height: 12px;
            }
        }

        .reserve {
            .Transition(all 0.35s);
            background-color: transparent;
            border: 3px solid black;
            outline: none;
            margin-top: 30px;
            margin-bottom: 40px;
            border-radius: 10px;
            padding: 15px 25px;
            font-weight: bold;
            cursor: pointer;
            align-self: center;

            &:hover {
                color: white;
                background-color: #cb9f6b;
                border-color: #cb9f6b;
            }
        }
    }

    .reservation-info {
        color: #555555;
        text-align: center;

        .opening-hours-label {
            font-weight: bold;
            margin-bottom: 10px;
        }

        .events-anchor {
            display: inline;
        }

        .opening-hours-closed,
        .events-anchor {
            font-weight: bold;
        }

        .opening-hours {
            margin-bottom: 5px;
        }

        .special-event {
            font-style: italic;
        }

        .phone-numbers {
            font-size: 30px;
            margin-top: 25px;

            .phone {
                color: white;
                padding: 15px;
                background-color: #cb9f6b;
                border-radius: 30px;
                width: 30px;
                height: 30px;
                margin: 0 25px;
            }
        }
    }
}

@media (max-width: 420px) {
    .reservation-section {
        .phone-numbers {
            font-size: 20px !important;
            display: flex;
            flex-direction: column;
            align-items: center;

            .phone {
                width: 20px !important;
                height: 20px !important;
            }
        }

        .one-line {
            margin-bottom: 0 !important;
        }

        .field-input {
            margin-bottom: 5px;
        }
    }
}
