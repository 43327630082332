@import '~react-image-lightbox/style.css';
.about-us-section {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  padding: 40px 0;
}
.about-us-section .about-content,
.about-us-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.about-us-section .content {
  padding-top: 10px;
  letter-spacing: 2px;
  color: #555;
  display: flex;
}
.about-us-section .content p {
  line-height: 1.5;
  margin-bottom: 20px;
  font-size: 14px;
  text-align: center;
}
.about-us-section .content .uppercase {
  text-transform: uppercase;
}
@media only screen and (max-width: 991px) {
  .about-us-section .content p {
    font-size: 12px;
  }
}
.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 0 0px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}
.footer .footer-info {
  background-color: black;
  color: #959595;
  text-align: center;
  width: 100%;
  font-size: 12px;
  padding: 15px 0;
  line-height: 1.5;
}
.footer .form {
  display: flex;
  flex-direction: column;
}
.footer .form .social-media {
  display: flex;
  align-self: center;
  margin-top: 30px;
  margin-bottom: 100px;
}
.footer .form .social-media .link {
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  transition: all 0.35s;
  font-size: 33px;
  color: white;
  border-radius: 22px;
  text-align: center;
}
.footer .form .social-media .link.facebook {
  margin-right: 7px;
  background-color: #3b5998;
  width: 43px;
  height: 37px;
  padding-top: 7px;
}
.footer .form .social-media .link.facebook:hover {
  background-color: #2d4373;
  color: #c0ccd6;
}
.footer .form .social-media .link.instagram {
  background-color: #3f729b;
  padding-bottom: 3px;
  width: 44px;
  height: 36px;
  padding: 6px 0 3px 1px;
}
.footer .form .social-media .link.instagram:hover {
  background-color: #305777;
  color: #c0ccd6;
}
.footer .newsletter-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer .newsletter-form button {
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  margin-bottom: 5px;
  align-self: center;
}
.footer .newsletter-form .email {
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  transition: all 0.35s;
  font-size: 25px;
  padding: 13px 25px;
  background-color: #cb9f6b;
  color: #222222;
  cursor: pointer;
}
.footer .newsletter-form .email:hover {
  background-color: #000;
  color: #fff;
}
.footer .newsletter-form input {
  width: 320px;
  height: 51px;
  background-color: #f9f9f9;
  border: none;
  border-radius: 0;
  box-shadow: none;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 0 20px 0 15px;
  color: #555555;
  font-weight: 400;
  outline: none;
}
@media (min-width: 991px) {
  .footer .footer-info {
    font-size: 14px;
  }
  .footer .newsletter-form {
    flex-direction: row;
  }
}
.contact-section {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  padding: 80px 0;
}
.contact-section .wrapper {
  background-color: #000000cc;
  padding: 15px 0 15px 15px;
  width: 55%;
}
.contact-section .wrapper .contact-container {
  display: flex;
  flex-wrap: wrap;
}
.contact-section .wrapper .contact-container iframe {
  margin-right: 20px;
}
.contact-section .wrapper .contact-info {
  color: white;
  flex-grow: 1;
}
.contact-section .wrapper .contact-info h1 {
  margin-top: 20px;
  font-family: 'Alegreya', serif;
  font-size: 36px;
  padding-bottom: 25px;
  border-bottom: 3px solid #ad7835;
  width: fit-content;
}
.contact-section .wrapper .contact-info .contact-form button {
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  transition: all 0.35s;
  outline: none;
  border: 1px solid white;
  color: white;
  background-color: transparent;
  border-radius: 20px;
  font-weight: 700;
  font-size: 14px;
  padding: 14px 16px;
  cursor: pointer;
}
.contact-section .wrapper .contact-info .contact-form button:hover {
  border: 1px solid #cb9f6b;
  background-color: #cb9f6b;
}
.contact-section .wrapper .contact-info .contact-form .field-input {
  margin-right: 20px;
  flex-grow: 1;
}
.contact-section .wrapper .contact-info .contact-form input,
.contact-section .wrapper .contact-info .contact-form textarea {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #fff;
  border-radius: 0px;
  background-color: transparent;
  color: #fff;
  height: 50px;
  font-size: 14px;
  margin-bottom: 30px;
  width: 100%;
}
.contact-section .wrapper .contact-info .contact-form textarea {
  resize: vertical;
}
@media (max-width: 990px) {
  .contact-section .wrapper {
    width: 85%;
  }
  .contact-section .contact-form .one-line {
    flex-direction: column;
  }
  .contact-section button {
    font-size: 12px !important;
  }
}
.reservation-section {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right bottom;
  font-size: 12px;
}
.reservation-section .title-section {
  margin-bottom: 30px !important;
}
.reservation-section .note {
  font-weight: bold;
  font-size: 13px;
  align-self: center;
  margin-bottom: 6px;
}
.reservation-section .note:last-of-type {
  margin-bottom: 40px;
}
.reservation-section .reservation-form {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}
.reservation-section .reservation-form .field-input,
.reservation-section .reservation-form .date-picker {
  margin-right: 5px;
}
.reservation-section .reservation-form .one-line {
  margin-bottom: 5px;
}
.reservation-section .reservation-form .comment {
  display: flex;
  flex: 1;
}
.reservation-section .reservation-form .comment input {
  flex: 1;
}
.reservation-section .reservation-form .field-input.wide input {
  width: 210px;
}
.reservation-section .reservation-form .field-input .error {
  font-size: 14px;
  top: 17px;
}
.reservation-section .reservation-form input:not(.date-picker input) {
  border-radius: 10px;
  border: solid 1px rgba(0, 0, 0, 0.54);
  height: 25px;
  padding: 10px;
  background-color: transparent;
}
.reservation-section .reservation-form .date-picker {
  width: 180px;
}
.reservation-section .reservation-form .date-picker > div {
  border-radius: 10px;
  border: solid 1px rgba(0, 0, 0, 0.54);
}
.reservation-section .reservation-form .date-picker > div fieldset {
  border: 0;
}
.reservation-section .reservation-form .date-picker input {
  font-size: 12px;
  height: 12px;
}
.reservation-section .reservation-form .reserve {
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  transition: all 0.35s;
  background-color: transparent;
  border: 3px solid black;
  outline: none;
  margin-top: 30px;
  margin-bottom: 40px;
  border-radius: 10px;
  padding: 15px 25px;
  font-weight: bold;
  cursor: pointer;
  align-self: center;
}
.reservation-section .reservation-form .reserve:hover {
  color: white;
  background-color: #cb9f6b;
  border-color: #cb9f6b;
}
.reservation-section .reservation-info {
  color: #555555;
  text-align: center;
}
.reservation-section .reservation-info .opening-hours-label {
  font-weight: bold;
  margin-bottom: 10px;
}
.reservation-section .reservation-info .events-anchor {
  display: inline;
}
.reservation-section .reservation-info .opening-hours-closed,
.reservation-section .reservation-info .events-anchor {
  font-weight: bold;
}
.reservation-section .reservation-info .opening-hours {
  margin-bottom: 5px;
}
.reservation-section .reservation-info .special-event {
  font-style: italic;
}
.reservation-section .reservation-info .phone-numbers {
  font-size: 30px;
  margin-top: 25px;
}
.reservation-section .reservation-info .phone-numbers .phone {
  color: white;
  padding: 15px;
  background-color: #cb9f6b;
  border-radius: 30px;
  width: 30px;
  height: 30px;
  margin: 0 25px;
}
@media (max-width: 420px) {
  .reservation-section .phone-numbers {
    font-size: 20px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .reservation-section .phone-numbers .phone {
    width: 20px !important;
    height: 20px !important;
  }
  .reservation-section .one-line {
    margin-bottom: 0 !important;
  }
  .reservation-section .field-input {
    margin-bottom: 5px;
  }
}
.events-section {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right bottom;
  padding-bottom: 40px;
}
.events-section .content {
  font-size: 12px;
}
.restaurant-menu {
  color: white;
}
.restaurant-menu .container {
  width: 85%;
}
.restaurant-menu .categories {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
}
.restaurant-menu .categories .category {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  color: #ccc;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
}
.restaurant-menu .categories .category.selected {
  color: #cda270;
}
.restaurant-menu .categories .category div {
  width: 6px;
  height: 6px;
  border-radius: 2px;
  background-color: white;
  margin-left: 20px;
}
.restaurant-menu .menu-products {
  display: flex;
  font-family: 'Alegreya', serif;
  margin: 25px 0;
  width: 100%;
}
.restaurant-menu .menu-products .product-chunk {
  border-right: 1px solid rgba(255, 255, 255, 0.4);
  padding: 0 20px;
  width: 50%;
  color: #ccc;
}
.restaurant-menu .menu-products .product-chunk:last-of-type {
  border-right: 0;
}
.restaurant-menu .menu-products .product-chunk .product {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
}
.restaurant-menu .menu-products .product-chunk .product .variety {
  display: flex;
  justify-content: space-between;
}
.restaurant-menu .menu-products .product-chunk .product .left {
  word-break: break-word;
  padding-right: 20px;
  font-size: 18px;
}
.restaurant-menu .menu-products .product-chunk .product .left.full {
  flex: 1;
  padding-right: 0 !important;
}
.restaurant-menu .menu-products .product-chunk .product .left .description {
  margin-right: 5px;
}
.restaurant-menu .menu-products .product-chunk .product .left .allergens {
  color: orangered;
  font-size: 15px;
  vertical-align: super;
}
.restaurant-menu .menu-products .product-chunk .product .left .type {
  color: orangered;
}
.restaurant-menu .menu-products .product-chunk .product .right {
  color: #cb9f6b;
  font-weight: bold;
  flex-shrink: 0;
  font-size: 16px;
}
@media (max-width: 420px) {
  .restaurant-menu .menu-products {
    flex-wrap: wrap;
  }
  .restaurant-menu .menu-products .product-chunk {
    width: 100%;
    border-right: 0;
  }
}
@media only screen and (min-width: 1200px) {
  .restaurant-menu .container {
    width: 60% !important;
  }
  .restaurant-menu .right {
    font-size: 18px !important;
  }
}
.gallery-section .container {
  width: 95%;
}
.gallery-section .content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.gallery-section figure {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  margin: 10px 0;
}
.gallery-section figure img {
  height: 260px;
}
.gallery-section figure:last-of-type {
  margin-right: 0;
}
.gallery-section figure:hover::before {
  -webkit-animation: shine 0.75s;
  animation: shine 0.75s;
}
.gallery-section figure::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}
.share-icons {
  display: flex;
}
.share-icons .icon-share {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.share-icons .icon-share:last-of-type {
  margin-right: 0px;
}
.share-icons .icon-share .icon {
  margin-right: 5px;
}
@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}
@keyframes shine {
  100% {
    left: 125%;
  }
}
@media only screen and (max-width: 420px) {
  .gallery-section .content {
    justify-content: center !important;
  }
  .gallery-section figure {
    margin: 10px;
  }
  .gallery-section figure img {
    height: 200px;
  }
}
html,
body {
  margin: 0;
  font-family: 'Lora', serif;
}
html,
body,
#root,
.app-body {
  height: 100%;
  width: 100%;
}
.form .one-line {
  display: flex;
  flex-wrap: wrap;
}
.form .field-input {
  position: relative;
}
.form .field-input .error {
  position: absolute;
  top: 18px;
  right: 10px;
  color: red;
}
a,
.events-anchor {
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  transition: all 0.35s;
  color: #cb9f6b;
  text-decoration: none;
  cursor: pointer;
}
a:hover,
.events-anchor:hover {
  text-decoration: underline;
  color: #1b846a;
}
.download-pdf {
  font-size: 25px;
}
.app-body {
  display: flex;
  flex-direction: column;
}
.app-body .fill-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  min-height: 100%;
}
.app-body .header {
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  transition: all 0.35s;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  border-bottom: 1px solid rgba(255, 255, 255, 0.9);
  color: white;
  position: fixed;
  top: 0;
  z-index: 1;
}
.app-body .header .menu-link {
  text-decoration: none;
  color: white;
}
.app-body .header .menu-link:hover {
  color: #cda270;
}
.app-body .header .dropdown-menu {
  padding: 10px 15px;
  display: none;
}
.app-body .header .dropdown-menu .menu-icon {
  font-size: 25px;
}
.app-body .header.scrolled {
  background-color: white;
}
.app-body .header.scrolled .title {
  color: #cda270;
}
.app-body .header.scrolled .header-link {
  color: #222222;
}
.app-body .header.scrolled .dropdown-menu {
  color: #cda270;
}
.app-body .header .title {
  cursor: pointer;
  font-family: 'Alegreya', serif;
  font-size: 38px;
  font-weight: 300;
  padding: 10px 15px;
  letter-spacing: 4px;
}
.app-body .header .links {
  display: flex;
  flex-wrap: wrap;
  padding: 0 15px;
}
.header-link {
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  transition: all 0.35s;
  padding: 25px 15px;
  font-size: 14px;
  cursor: pointer;
}
.header-link:hover,
.header-link.active {
  color: #cda270;
}
.header-link.menu {
  font-style: italic;
}
.header-link.menu-link {
  color: unset;
  text-decoration: none;
}
.about-us-section,
.contact-section,
.reservation-section,
.events-section,
.container,
.restaurant-menu,
.gallery-section,
.container .title-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container {
  padding: 0 10px;
}
.container .title-section {
  margin-bottom: 15px;
}
.container .title-section h1 {
  margin-top: 20px;
  font-family: 'Alegreya', serif;
  margin-bottom: 20px;
  font-weight: 700;
  letter-spacing: 2px;
  font-size: 36px;
  text-align: center;
}
.modal {
  display: flex;
}
.modal .vacation {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 10px 15px 15px 15px;
  width: 30%;
  margin: auto;
  text-align: center;
}
.modal .vacation h2 {
  margin: 0;
  align-self: flex-end;
  cursor: pointer;
}
.modal .vacation .closure-message {
  white-space: pre-wrap;
}
@media only screen and (max-width: 1024px) {
  .header .title {
    font-size: 24px !important;
    letter-spacing: 0 !important;
  }
  .header .header-link {
    font-size: 12px !important;
    padding: 50px !important;
  }
}
@media only screen and (max-width: 440px) {
  .header {
    flex-wrap: nowrap !important;
  }
  .header .links {
    display: none !important;
  }
  .header .dropdown-menu {
    display: block !important;
  }
  .header-link {
    padding: 10px !important;
  }
}
@media (min-width: 768px) {
  .container {
    width: 75%;
  }
}
