.about-us-section {
    .FillImage(center bottom);
    padding: 40px 0;

    .about-content,
    & {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .content {
        padding-top: 10px;
        letter-spacing: 2px;
        color: #555;
        display: flex;

        p {
            line-height: 1.5;
            margin-bottom: 20px;
            font-size: 14px;
            text-align: center;
        }

        .uppercase {
            text-transform: uppercase;
        }
    }
}

@media only screen and (max-width: 991px) {
    .about-us-section .content p {
        font-size: 12px;
    }
}
