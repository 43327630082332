.restaurant-menu {
    color: white;

    .container {
        width: 85%;
    }

    .categories {
        display: flex;
        flex-wrap: wrap;
        width: 90%;

        .category {
            display: flex;
            align-items: center;
            padding: 10px 20px;
            color: #ccc;
            font-weight: 700;
            font-size: 18px;
            cursor: pointer;

            &.selected {
                color: #cda270;
            }

            div {
                width: 6px;
                height: 6px;
                border-radius: 2px;
                background-color: white;
                margin-left: 20px;
            }
        }
    }

    .menu-products {
        display: flex;
        font-family: 'Alegreya', serif;
        margin: 25px 0;
        width: 100%;

        .product-chunk {
            border-right: 1px solid rgba(255, 255, 255, 0.4);
            padding: 0 20px;
            width: 50%;
            color: #ccc;

            &:last-of-type {
                border-right: 0;
            }

            .product {
                display: flex;
                justify-content: space-between;
                padding: 12px 0;

                .variety {
                    display: flex;
                    justify-content: space-between;
                }

                .left {
                    word-break: break-word;
                    padding-right: 20px;
                    font-size: 18px;

                    &.full {
                        flex: 1;
                        padding-right: 0 !important;
                    }

                    .description {
                        margin-right: 5px;
                    }

                    .allergens {
                        color: orangered;
                        font-size: 15px;
                        vertical-align: super;
                    }

                    .type {
                        color: orangered;
                    }
                }

                .right {
                    color: #cb9f6b;
                    font-weight: bold;
                    flex-shrink: 0;
                    font-size: 16px;
                }
            }
        }
    }
}

@media (max-width: 420px) {
    .restaurant-menu {
        .menu-products {
            flex-wrap: wrap;

            .product-chunk {
                width: 100%;
                border-right: 0;
            }
        }
    }
}

@media only screen and (min-width: 1200px) {
    .restaurant-menu {
        .container {
            width: 60% !important;
        }

        .right {
            font-size: 18px !important;
        }
    }
}
