.contact-section {
    .FillImage(center bottom);
    padding: 80px 0;

    .wrapper {
        background-color: #000000cc;
        padding: 15px 0 15px 15px;
        width: 55%;

        .contact-container {
            display: flex;
            flex-wrap: wrap;

            iframe {
                margin-right: 20px;
            }
        }

        .contact-info {
            color: white;
            flex-grow: 1;

            h1 {
                margin-top: 20px;
                font-family: 'Alegreya', serif;
                font-size: 36px;
                padding-bottom: 25px;
                border-bottom: 3px solid #ad7835;
                width: fit-content;
            }

            .contact-form {
                button {
                    .Transition(all 0.35s);
                    outline: none;
                    border: 1px solid white;
                    color: white;
                    background-color: transparent;
                    border-radius: 20px;
                    font-weight: 700;
                    font-size: 14px;
                    padding: 14px 16px;
                    cursor: pointer;

                    &:hover {
                        border: 1px solid #cb9f6b;
                        background-color: #cb9f6b;
                    }
                }

                .field-input {
                    margin-right: 20px;
                    flex-grow: 1;
                }

                input,
                textarea {
                    border-top: none;
                    border-left: none;
                    border-right: none;
                    border-bottom: 1px solid #fff;
                    border-radius: 0px;
                    background-color: transparent;
                    color: #fff;
                    height: 50px;
                    font-size: 14px;
                    margin-bottom: 30px;
                    width: 100%;
                }

                textarea {
                    resize: vertical;
                }
            }
        }
    }
}

@media (max-width: 990px) {
    .contact-section {
        .wrapper {
            width: 85%;
        }

        .contact-form {
            .one-line {
                flex-direction: column;
            }
        }

        button {
            font-size: 12px !important;
        }
    }
}
